import {
  DateField,
  DateTimeInput,
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import { transformInput } from '../../../lib/utils';
  
export const JourneyEdit = () => (
  <Edit transform={transformInput}>
  <SimpleShowLayout>
    <TextField source="id" />
    <DateField source="createdAt" showTime />
    <DateField source="updatedAt" showTime />
  </SimpleShowLayout>
  <SimpleForm>
    <DateTimeInput source="startTime" />
    <DateTimeInput source="finishTime" />
    <ReferenceInput source="LocalId" reference="locals" perPage={10000} filter={{ active: true }}>
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput source="DriverId" reference="drivers" perPage={10000}>
      <SelectInput optionText="name" />
    </ReferenceInput>
  </SimpleForm>
  </Edit>
);
  